:root {

    --themeColor1: #222;
    --themeColor2: #160b0b;
    --themeColor3: #333;
    --themeColor: #000;
    --bg-color: #100202;
    --bg-color1: #160b0b;
    --color: #007119;
    --color1: #fff;
    --color2: #ccc;
}

body {
    background-color: #000;
    font-family: 'Poppins', sans-serif;
}

.right-container {
    display: flex;

}

.right-lession-section {
    padding: 40px 20px 100px 20px;
    /* background-color: var(--bg-color); */
    box-shadow: 0 0 4px rgb(122 110 110 / 50%);
    width: 80%;

}



.lession-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
}

.header-btn-section {
    display: flex;
    align-items: center;
}

.header-btn-section a {
    width: 40px;
    height: 35px;
    padding: 6px;
    background: var(--color);
    margin-left: 10px
}

.header-btn-section .right_arrow {
    transform: rotate(180deg);
}

.header-btn-section a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.right-container .add_section {
    width: 20%;
    height: 100%;
    border-left: 1px solid #007119;
}

.introduction-section {
    background-color: var(--themeColor2);
    padding: 30px;
}

.try-it-section {
    position: relative;
    background-color: var(--themeColor2);
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.try-it-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.try-it-section-header h5 {
    color: var(--color);
    font-size: 18px;
    margin-bottom: 0;
}

.try-it-section-header a {
    color: var(--color);
    font-size: 16px;
}

.try_it-code {
    padding: 15px;
    background-color: var(--themeColor);
    border: 2px solid var(--color);
    border-left: 5px solid var(--color);
    color: var(--color);
}

.small_des {
    border: 1px dotted #0071199c;

    padding: 15px 0;
    border-left: none;
    border-right: none;
}

ul.list-one li {
    padding: 5px 0;
    color: var(--color);
    font-size: 14px;
}

.table-one {
    margin-top: 30px;
    border: none !important;

}

.table>:not(caption)>*>* {
    background-color: transparent !important;
}

.table-one th,
.table-one td {
    border: 1px solid var(--color) !important;
    color: var(--color) !important;
}

.pageStructure {
    margin: 20px 0;
}

.pageStructure p {
    margin: 0;
}

.html_frame {
    border: 1px dotted var(--color);
    padding: 5px;
    color: var(--color);
}

.head_frame {
    border: 1px dotted var(--color);
    padding: 5px;
    margin: 10px;
}

.title_frame,
.body_frame {
    border: 1px dotted var(--color);
    padding: 5px;
    margin: 10px;
}

.body_content_frame {
    border: 1px dotted var(--color);
    padding: 15px;
    margin: 10px;
}

.body_content_framei {
    border: 1px dotted var(--color);
    padding: 15px;
    margin: 10px;
}

.header-btn-section-footer {
    margin-top: 50px;
    width: 100%;
    justify-content: space-between !important;
}

.left_menu_layout {
    /* padding-top: 30px; */
    height: 60vh;
    position: sticky;
    top: 100px;
}

.left_menu_layout h4 {
    margin-bottom: 30px;
    padding: 0 10px;
    font-size: 20px !important;
}

.left_menu_layout ul {
    padding-left: 0;
    list-style: none;
}

.left_menu_layout ul li {
    margin: 10px 0;
}

.left_menu_layout ul li a {
    text-decoration: none;
    color: var(--color);
    padding: 5px 10px 5px 15px;
    display: block;
    transition: 0.5s;
    position: relative;
    font-size: 14px;
}

.left_menu_layout ul li a:hover {
    background-color: var(--color);
    color: var(--color2);
}

.left_menu_layout ul li a::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 0;
    height: 100%;
    width: 2px;
    background: var(--color);
}

footer {
    /* background-color: var(--bg-color1); */
    /* background-image: url('../images/footer.jpg'); */
    background-size: cover;
    background-position: center;
    height: 100vh;
}

table tr td {
    font-size: 14px;
}