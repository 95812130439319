.privacy{
    color:#007119
}

.privacy h4{
    margin-top: 20px;
    margin-bottom: 20px;
    text-decoration: underline;
}

.privacy a{
    text-decoration: underline;
    color: #007119 !important;
}