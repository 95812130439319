:root {
    --themeColor: #000;
    --color: #007119;
    --color1: #fff;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--themeColor) !important;
    position: relative;
    width: 100%;
}

.td-none {
    text-decoration: none;
}

.wrapper {
    padding: 0 0;
}

.main-section {
    padding: 100px 0px;
}

.p-l-r {
    padding-left: 100px;
    padding-right: 100px;
}

.top {
    padding-top: 80px;

}

.bottom {
    padding-bottom: 80px;
}

header {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 111;
}

.bg_light_bg {
    background-color: #000 !important;
}

.logo_img img {
    max-width: 25%;
}

.nav-link {
    color: var(--color) !important;
    font-family: 'ClashDisplay-Medium';
}

.auth-box ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.auth-box ul li {
    width: 100%;
}

.auth-box a {
    padding: 5px 30px;
    border-radius: 5px;
    text-decoration: none;
    color: var(--color);
    margin-left: 10px;
    font-family: 'ClashDisplay-Medium';
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        justify-content: flex-end !important;
        width: 100% !important;
        align-items: center;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        padding-right: 50px;
    }
}

.main {
    padding: 100px 0px;
}

.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
}

/* 12 */
.btn-12 {
    position: relative;
    border: none;
    box-shadow: none;
    width: 130px;
    height: 40px;
    line-height: 42px;
    -webkit-perspective: 230px;
    perspective: 230px;
}

.btn-12 span:nth-child(1) {
    background-color: var(--color);
    background: linear-gradient(0deg, var(--color) 0%, #02340d 100%);
    color: #fff;
}

.btn-12 span:nth-child(2) {
    background: linear-gradient(0deg, #02340d 0%, var(--color) 100%);
    color: #fff;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
}

.btn-12 span {
    background: rgb(0, 172, 238);
    display: block;
    position: absolute;
    width: 130px;
    height: 40px;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    border-radius: 5px;
    margin: 0;
    left: 0;
    top: 0;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.btn-12 span:nth-child(1) {
    box-shadow: 0 -7px 5px 0px #fff9, 0 -4px 5px 0px #fff9, 0 7px 20px 0px #0002, 0 4px 5px 0px #0001;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
}

.btn-12 span:nth-child(2) {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
}

.btn-12:hover span:nth-child(1) {
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

.btn-12:hover span:nth-child(2) {
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    color: transparent;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.banner-section {
    height: 90vh;
    width: 100%;
    position: relative;
}

.banner-section .container-fluid {
    height: 100%;
}

.banner-section .row {
    height: 100%;
}

.banner-section .banner-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner-section .banner-content h1 {
    font-family: 'ClashDisplay-Bold';
    font-size: 55px;
    color: var(--color1);
    line-height: 60px;
}

.banner-section .banner-content h1 span {
    color: var(--color);
}

.banner-section .banner-content p {
    font-family: 'ClashDisplay-Regular';
    font-size: 18px;
    color: var(--color);
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.banner-right-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tag-list {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem 0;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
}

.loop-slider {
    .inner {
        display: flex;
        width: fit-content;
        animation-name: loop;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: var(--direction);
        animation-duration: var(--duration);
    }
}

.tag {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    color: var(--color1);
    font-size: 0.9rem;
    background-color: var(--color);
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem;
    box-shadow:
        0 0.1rem 0.2rem rgb(0 113 25 / 20%),
        0 0.1rem 0.5rem rgb(0 113 25 / 30%),
        0 0.2rem 1.5rem rgb(0 113 25 / 40%);

    span {
        font-size: 1.2rem;
        color: #64748b;
    }
}

.fadeBox {
    pointer-events: none;
    background: linear-gradient(90deg, #000, transparent 40%, transparent 60%, #000);
    position: absolute;
    inset: 0;
}

@keyframes loop {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.heading {
    font-size: 55px;
    font-family: 'ClashDisplay-Bold';
    letter-spacing: 1px;
}

.heading span {
    color: var(--color);
}

.section {
    /* padding: 10px 0; */
    position: relative;
}

.shadow_1 {
    position: absolute;
    width: 743px;
    height: 500px;
    /* background: rgb(255,255,255); */
    left: 50%;
    top: -28%;
    transform: translateX(-50%);
    padding: 50px;
    z-index: 1;
}

.shadow_1 img {
    width: 100%;
    filter: blur(80px);
}

.section-1 h2 {
    color: var(--color1);
    text-align: center;
    z-index: 2;
    position: relative;
    -webkit-text-stroke: 1px var(--color);
}

.section-1 h2 span {
    color: var(--color);
    -webkit-text-stroke: 0.4px var(--color1);
}

.course_card {
    background-color: #100f0f75;
    border-radius: 30px 30px 0px 0px;
    border: 2px solid var(--color);
    overflow: hidden;
}

.course_card .img_box {
    width: 100%;
    height: 200px;
    background-image: url("../images/html.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.course_card .img_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course_card .content {
    padding: 10px 0px 0px 20px;
}

.course_card .icon_box {
    width: 80px;
    height: 80px;
    padding: 10px;
}

.course_card .icon_box img {
    width: 100%;
}

.course_card h3 {
    color: var(--color1);
    font-size: 20px;
    font-family: 'ClashDisplay';
}

.course_card p {
    font-size: 16px;
    color: var(--color1);
    font-family: 'ClashDisplay-Regular';
}

.slider-section {
    padding-top: 50px;
}

.slider-section-blog {
    padding-top: 50px;
    height: 300px;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: 100%;
}

.icons li {
    height: 40px;
    width: 40px;
    display: flex;
    padding: 13px;
    margin: 0 10px;
    border-radius: 50px;
    border: 2px solid #fff;
    transition: all 200ms ease;
    text-decoration: none;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.icons a {
    text-align: center;
    background: #000;
    margin-top: -5px;
}

.icons i {
    color: #007119;
}

.icons li:hover,
.icons li:active {
    color: #007119;
    background: none;
    cursor: pointer !important;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    text-decoration: none;
    font-weight: bold;
}

.float_icon {
    display: none;
}

footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--color); */
    padding: 10px;
}

footer ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
}

footer ul li {
    margin: 0 10px;
}

.masonry__item {
    width: 100%;
}

.post-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 1.75em 1.5em;
    word-wrap: break-word;
    background-color: var(--post-card__BackgroundColor, rgba(0, 0, 80, .025));
    background-clip: border-box;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
}

.post-card__thumbnail {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 1.375em;
    background-color: rgba(0, 0, 80, .025);
}

a.post-card__thumbnail img {
    -webkit-transition: -webkit-filter .4s ease-in-out;
    transition: -webkit-filter .4s ease-in-out;
    transition: filter .4s ease-in-out;
    transition: filter .4s ease-in-out, -webkit-filter .4s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
}

.post-card__thumbnail img {
    width: 100%;
    /* height: 100%; */
    -o-object-fit: cover;
    object-fit: cover;
}

.post-card__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    -webkit-box-orient: vertical;
    -ms-flex: 1 1 auto;
    -ms-flex-direction: column;
}

.post-card__meta {
    margin-bottom: 1.25em;
    color: var(--post-card-meta__Color, var(--text--secondary__Color));
    font-family: var(--post-card-meta__FontFamily, var(--headings__FontFamily));
    font-size: var(--post-card-meta__FontSize, calc(var(--font-size--base)*.8125));
}

.post-card__title {
    margin-top: 0;
    margin-bottom: .625em;
    font-family: 'ClashDisplay-Medium';
    font-size: 30px;
    color: #fff;
}

.post-card__title a {
    text-decoration: none;
    color: #fff;
}

.post-card__meta>span {
    display: inline-block;
    margin-right: .75em;
    color: #b3abab;
}

.post-card__meta a {
    text-decoration: none;
    color: #b3abab;

}

.post-card__meta>span+span:before {
    display: inline-block;
    width: 1.75em;
    height: 1px;
    margin-right: .75em;
    content: '';
    vertical-align: .1875em;
    opacity: .75;
    background-color: #b3abab;
}

.says,
.screen-reader-text {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal !important;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    word-wrap: normal !important;
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.courser-section {
    position: relative;
}

.courser-section .left-section {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.courser-section .left-section h4 {
    font-size: 80px;
    color: #fff;
    font-family: 'ClashDisplay-Medium';
}

.courser-section .left-section p {
    font-size: 22px;
    font-family: 'ClashDisplay-Regular';
    color: #fff;
    margin-bottom: 30px;
}

.courser-section .left-section a {
    text-decoration: none;
    font-family: 'ClashDisplay-Regular';
    font-size: 18px;
}

.blog-content h5 {
    color: #fff;
    font-weight: bold;
}

.blog-content .code {
    color: var(--color);
    font-weight: bold;
    font-size: 20px;
}

.blog-content p {
    color: #fff;
}

.blog-content .p1 {
    margin-left: 30px;
}

.blog-content .p2 {
    margin-left: 60px;
}

.try_it-code .ml1 {
    margin-left: 30px;
}

.try_it-code .ml2 {
    margin-left: 60px;
}

.try_it-code .ml3 {
    margin-left: 90px;
}

@media(max-width:991px) {
    .float_icon {
        display: block;
        position: fixed;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: fit-content;
    }

    .float_icon li {
        margin-bottom: 10px;
        background-color: #000;
    }

    .navbar-toggler {
        display: none !important;
    }
}

@media(max-width:600px) {

    header,
    .wrapper {
        padding: 0 10px;
    }

    .banner-section {
        height: auto;
        padding-top: 100px;
    }

    .banner-section .banner-content h1 {
        font-size: 40px;
        line-height: 45px;
    }

    .logo_img {
        width: 250px;
    }

    .logo_img img {
        max-width: 60%;
    }

    .banner-right-content {
        margin-top: 15px;
    }

    footer {
        padding: 20px 0;
    }

}