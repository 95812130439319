:root {
   
    --themeColor1: #222;
    --themeColor2: #111;
    --themeColor3: #333;
    --themeColor: #000;
    --gray:gray;
    --bg-color2: #1e0909;
    --color: #007119;
    --color1: #fff;
}

h3{
    color: var(--color);
    font-size: 30px;
}
h4{
    color: var(--color);
    font-size: 25px;
}

h1 {
    color: var(--color);
    font-size: 35px;
}

h2 {
    color: var(--color);
    font-size: 25px;
}

p {
    font-size: 14px;
    margin: 10px 0;
}

p.paragraph1 {
    color: var(--color);
}
code.span1{
    background-color: var(--color);
    color: var(--color1);
}
p.paragraph1 strong{
    color: #ccc;
}
.tagname{
    color: var(--color);
    font-size: 25px;
}
.divider_line{
    border-top: 1px dotted #0071199c !important;
    opacity: 1 !important;
    margin: 30px 0 !important;
}
.note1{
    padding: 20px;
    border: 1px solid var(--color);
    color: var(--gray);
    font-size: 14px;
    background-color: var(--bg-color2);
}
.example_img{
    opacity: 0.3;
}